import { jsx as _jsx } from "react/jsx-runtime";
import { CoralThemeProvider } from '@krakentech/coral';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
const ThemeProvider = ({ children, theme, coralConfig }) => {
    var _a, _b, _c, _d, _e, _f;
    return (_jsx(CoralThemeProvider, { config: Object.assign(Object.assign({}, coralConfig), { LinkComponent: ReactRouterLink, 
            // TODO - Remove when toggle button font weight is same as button
            // https://app.asana.com/0/1200668202270525/1208160082413293/f
            overrides: Object.assign(Object.assign({}, coralConfig === null || coralConfig === void 0 ? void 0 : coralConfig.overrides), { components: Object.assign(Object.assign({}, (_a = coralConfig === null || coralConfig === void 0 ? void 0 : coralConfig.overrides) === null || _a === void 0 ? void 0 : _a.components), { togglebutton: Object.assign(Object.assign({}, (_c = (_b = coralConfig === null || coralConfig === void 0 ? void 0 : coralConfig.overrides) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.togglebutton), { button: Object.assign(Object.assign({}, (_f = (_e = (_d = coralConfig === null || coralConfig === void 0 ? void 0 : coralConfig.overrides) === null || _d === void 0 ? void 0 : _d.components) === null || _e === void 0 ? void 0 : _e.togglebutton) === null || _f === void 0 ? void 0 : _f.button), { fontWeight: '500' }) }) }) }) }), children: _jsx(SCThemeProvider, { theme: theme, children: children }) }));
};
export default ThemeProvider;
